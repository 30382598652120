/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

body {
  font-family: "Open Sans", sans-serif;
  background-color: #040404;
  color: #fff;
  position: relative;
  background: transparent;
  z-index: 1;
  text-align: justify;
  text-justify: inter-word;
}

body::before {
  content: "";
  position: fixed;
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: -1;
}
@media (min-width: 1024px) {
  body::before {
    background-attachment: fixed;
  }
}

canvas {
  display: block;
  background: #040404 url("../img/bg-4.webp") top right no-repeat; 
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  position: fixed;
  z-index: -1;
  mix-blend-mode: screen;
}

a {
  color: #18d26e;
  text-decoration: none;
}

a:hover {
  color: #35e888;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: ease-in-out 0.3s;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 997;
  overflow-y: auto;
}
#header * {
  transition: ease-in-out 0.3s;
}
#header h1 {
  font-size: 48px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
#header h1 a, #header h1 a:hover {
  color: rgb(255, 0, 55);
  line-height: 1;
  display: inline-block;
}
#header h1 a:hover {
  color: #fff;
}
#header h2 {
  font-size: 24px;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.8);
}
#header h2 span {
  color: #fff;
  border-bottom: 2px solid #18d26e;
  padding-bottom: 6px;
  font-weight: bolder;
}
#header img {
  padding: 0;
  margin: 0;
}
#header .social-links {
  margin-top: 40px;
  display: flex;
}
#header .social-links a {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  line-height: 1;
  margin-right: 8px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
#header .social-links a:hover {
  background: #18d26e;
}
@media (max-width: 992px) {
  #header h1 {
    font-size: 36px;
  }
  #header h2 {
    font-size: 20px;
    line-height: 30px;
  }
  #header .social-links {
    margin-top: 15px;
  }
  #header .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Header Top */
#header.header-top {
  height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
}
#header.header-top .social-links, #header.header-top h2 {
  display: none;
}
#header.header-top h1 {
  margin-right: auto;
  font-size: 36px;
}
#header.header-top .container {
  display: flex;
  align-items: center;
}
#header.header-top .navbar {
  margin: 0;
}
@media (max-width: 768px) {
  #header.header-top {
    height: 60px;
  }
  #header.header-top h1 {
    font-size: 26px;
  }
}
#header .sale {
  font-size: 30px;
  margin-top: 50px;
  color: rgba(255, 255, 255, 0.8);
  animation: blink 1.7s infinite;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
#header .sale span {
  font-size: 45px;
  margin-top: 50px;
  color: rgba(255, 4, 4, 0.8);
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
  margin-top: 35px;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar li + li {
  margin-left: 30px;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #18d26e;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}
.navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
  visibility: visible;
  width: 25px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #fff;
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  position: fixed;
  right: 15px;
  top: 15px;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
  margin-top: 0;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 45px;
  left: 15px;
  padding: 10px 0;
  overflow-y: auto;
  transition: 0.3s;
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.navbar-mobile li {
  padding: 12px 20px;
}
.navbar-mobile li + li {
  margin: 0;
}
.navbar-mobile a, .navbar-mobile a:focus {
  font-size: 16px;
  position: relative;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 140px;
  bottom: 100%;
  opacity: 0;
  transition: ease-in-out 0.4s;
  z-index: 2;
}
section.section-show {
  top: 100px;
  bottom: auto;
  opacity: 1;
  padding-bottom: 45px;
}
section .container {
  background: rgba(0, 0, 0, 0.9);
  padding: 30px;
}
@media (max-width: 768px) {
  section {
    top: 120px;
  }
  section.section-show {
    top: 80px;
  }
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 15px;
  margin: 0 0 20px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #4ceb95;
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: -15px 0 15px 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.section-separator::after  {
  content: "";
  width: 60%;
  height: 1px;
  display: inline-block;
  background: #4d524f;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 1px;
  margin-bottom: 25px;
}
.section-separator2::after  {
  content: "";
  width: 60%;
  height: 1px;
  display: inline-block;
  background: #4ceb95;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 1px;
  margin-bottom: 25px;
}
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-me .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #18d26e;
}
.about-me .content ul {
  list-style: none;
  padding: 0;
}
.about-me .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.about-me .content ul strong {
  margin-right: 10px;
}
.about-me .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #18d26e;
  line-height: 0;
}
.about-me .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}
.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: rgba(255, 255, 255, 0.08);
}
.counts .count-box i {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: rgba(255, 255, 255, 0.1);
  padding: 12px;
  color: #18d26e;
  border-radius: 50px;
  line-height: 0;
}
.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #fff;
}
.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 50px;
  display: block;
  background: none;
  border-radius: 0;
}
.skills .progress .skill {
  padding: 10px 0;
  margin: 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.skills .progress .skill .val {
  float: right;
  font-style: normal;
}
.skills .progress-bar-wrap {
  background: rgba(255, 255, 255, 0.2);
}
.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 2s;
  background-color: #18d26e;
}

/*--------------------------------------------------------------
# Interests
--------------------------------------------------------------*/
.interests .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #18d26e;
}
.interests .content ul {
  list-style: none;
  padding: 10px;
  margin-top: 30px;
}
.interests .content ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.interests .content ul strong {
  margin-right: 10px;
}
.interests .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #18d26e;
  line-height: 0;
}
.interests .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel, .testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 320px;
}
.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
  z-index: 2;
  border: 6px solid rgba(255, 255, 255, 0.12);
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 45px;
  color: #fff;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 0 45px;
}
.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.25);
  font-size: 26px;
}
.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 0 15px;
  padding: 20px 20px 60px 20px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}
.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #18d26e;
}

/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume .resume-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
}
.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  position: relative;
}
.resume .resume-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #18d26e;
  margin-bottom: 10px;
}
.resume .resume-item h5 {
  font-size: 16px;
  background: rgba(255, 255, 255, 0.15);
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}
.resume .resume-item ul {
  padding-left: 20px;
}
.resume .resume-item ul li {
  padding-bottom: 10px;
}
.resume .resume-item:last-child {
  padding-bottom: 0;
}
.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #18d26e;
  border: 2px solid #18d26e;
}
.resume .roadmap {
  padding-left:25%;
  padding-right: 25%;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  background: rgba(204, 204, 204, 0.1);
  padding: 30px 50px;
  transition: all ease-in-out 0.3s;
}
.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #18d26e;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.services .icon-box .icon i {
  color: #fff;
  font-size: 55px;
}
.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 64px;
}
.services .icon-box h4 a {
  color: #fff;
}
.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.services .icon-box:hover {
  background: #004e61;
  border-color: #18d26e;
}
.services .icon-box:hover .icon {
  background: #fff;
}
.services .icon-box:hover .icon i {
  color: #18d26e;
}
.services .icon-box:hover .icon::before {
  background: #35e888;
}
.services .icon-box:hover h4 a, .services .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #18d26e;
}
.portfolio .portfolio-item {
  margin-bottom: 30px;
}
.portfolio .content ul {
  list-style: none;
  padding: 0;
}
.portfolio .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.portfolio .content ul strong {
  margin-right: 10px;
}
.portfolio .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #18d26e;
  line-height: 0;
}
.portfolio .content p:last-child {
  margin-bottom: 0;
}
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 15px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}
.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px 10px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  margin: 0 3px 10px 3px;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
}
.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  background: #18d26e;
}
.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}
.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}
.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}
.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}
.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}
.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #63eda3;
}
.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}
.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}
.portfolio .info-box {
  color: #444444;
  padding: 20px;
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
}
.portfolio .info-box i.bx {
  font-size: 30px;
  color: #18d26e;
  border-radius: 50%;
  padding: 10px;
  float: left;
  background: rgba(255, 255, 255, 0.1);
}
.portfolio .info-box h3 {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 700;
  margin: 10px 0 8px 68px;
  display: flex;
  justify-content: center;
}
.portfolio .info-box p {
  padding: 0;
  color: #fff;
  line-height: 24px;
  font-size: 14px;
  margin-top: 10px;
  padding-right: 20px;
}
.portfolio .info-box p:hover {
  color: #18d26e;
}
.portfolio .info-box .social-links {
  margin-top: 10px;
  display: flex;
}
.portfolio .info-box .social-links a {
  font-size: 30px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  margin-right: 12px;
  transition: 0.3s;
}
.portfolio .info-box .social-links a:hover {
  color: #18d26e;
}
.portfolio .svg {
  width: 30px;
  height: 30px;
  fill: #fff;
  margin-bottom: 10px;
} 

.portfolio .iframe {
  height: 100vh;
}
/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
}
.portfolio-details .container {
  padding-top: 20px;
  padding-bottom: 40px;
}
.portfolio-details .portfolio-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-info {
  padding-top: 45px;
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-info p {
  font-size: 15px;
  padding: 15px 0 0 0;
}
@media (max-width: 992px) {
  .portfolio-details .portfolio-info {
    padding-top: 20px;
  }
}
.portfolio-details .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
}
.portfolio-details .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #18d26e;
}

/*--------------------------------------------------------------
# Club
--------------------------------------------------------------*/
.club .icon-box {
  text-align: center;
  background: rgba(204, 204, 204, 0.1);
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
}
.club .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #18d26e;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.club .icon-box .icon i {
  color: #fff;
  font-size: 55px;
}
.club .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.club .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 64px;
}
.club .icon-box h4 a {
  color: #fff;
}
.club .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.club .icon-box:hover {
  background: #18d26e;
  border-color: #18d26e;
}
.club .icon-box:hover .icon {
  background: #fff;
}
.club .icon-box:hover .icon i {
  color: #18d26e;
}
.club .icon-box:hover .icon::before {
  background: #35e888;
}
.club .icon-box:hover h4 a, .club .icon-box:hover p {
  color: #fff;
}
.club .container {
  background: url("../img/club.webp") top right no-repeat;
  background-size: cover; 
  opacity: 1;
}

/*--------------------------------------------------------------
# Credits
--------------------------------------------------------------*/
.credits {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 15px;
  text-align: right;
  font-size: 13px;
  color: #fff;
  z-index: 999999;
}
@media (max-width: 992px) {
  .credits {
    text-align: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
  }
}
.credits a {
  color: #18d26e;
  transition: 0.3s;
}
.credits a:hover {
  color: #fff;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer a {
  color: #18d26e;
  transition: 0.3s;
}
.footer a:hover {
  color: #fff;
}

/*--------------------------------------------------------------
# Root
--------------------------------------------------------------*/
:root {
  --button-bg: #2269c7;
  --button-active-bg: #1d9b2e;
  --small-button-bg: #919191;
  --small-button-active-bg: #646464;
  --button-text: #ffffff;
  --card: #ffffff;
  --accountText: #ffffff;
  --statusText: #ffffff;
  --success: #24a13f;
  --warning: #ca5824;
  --error: #ca2f24;
}

.card {
  background-color: var(--card);
  display: flex;
  flex-direction: column;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 300px;
  transition: 0.3s;
  margin: 50px;
  position: relative;
}

.card:hover {
  box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
}

.card_body {
  padding: 15px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_body_view_contract {
  padding-right: 15px;
  padding-left: 15px;
}

.card_footer {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.spin {
  animation: item-spin infinite 20s linear;
}

.statusText {
  color: var(--statusText);
  font-weight: normal;
  text-align: center;
}

.accountText {
  color: var(--accountText);
  font-weight: bold;
}

.ns {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

._90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(-90deg);
}

.button {
  display: inline-block;
  padding: 7px 14px;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  border-radius: 15px;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 10px;
}

button:disabled {
  opacity: 0.5;
}

.button:active {
  background-color: var(--button-active-bg);
}

.small_button {
  display: inline-block;
  padding: 5px 15px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: var(--button-text);
  background-color: var(--small-button-bg);
  border: none;
  border-radius: 15px;
  margin-bottom: 2px;
}

.small_button:disabled {
  opacity: 0.5;
}

.small_button:active {
  background-color: var(--small-button-active-bg);
}

@keyframes item-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-box2 {
  text-align: center;
  background: rgba(204, 204, 204, 0.1);
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
}
.icon-box2 .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #18d26e;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.icon-box2 .icon i {
  color: #fff;
  font-size: 50px;
}
.icon-box2 .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.icon-box2 h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 64px;
}
.icon-box2 h4 a {
  color: #fff;
}
.icon-box2 p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.icon-box2:hover {
  background: #2b373d;
  border-color: #18d26e;
}
.icon-box2:hover .icon {
  background: #fff;
}
.icon-box2:hover .icon i {
  color: #18d26e;
}
.icon-box2:hover .icon::before {
  background: #35e888;
}
.icon-box2:hover h4 a, .icon-box2:hover p {
  color: #fff;
}